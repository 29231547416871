import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'
import scrollTo from 'gatsby-plugin-smoothscroll'
import SVG from 'react-inlinesvg'

import {
  Wrapper,
  Content,
  LogoText,
  MenuListItem,
  FullMenu,
  NavList,
  NavListItem,
  NavListButtons,
} from './styles.js'

import Button from '@atoms/Button'
import Link from '@atoms/Link/index.js'
import LogoHeader from '@atoms/LogoHeader'
import TextBox from '@atoms/TextBox'

import SectionGrayMiddle from '@molecules/SectionGrayMiddle'

import LogoTextSVG from '@images/logo-text.svg'
import SignUpButton from '../../molecules/SignUpButton/index.js'

const Header = ({ path, navItems }) => {
  const [menuOpened, setMenuOpened] = useState(false)
  const [scrollOnTop, setScrollOnTop] = useState(true)
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => {
    let distanceFromTop = window.pageYOffset

    return function () {
      let newDistanceFromTop = window.pageYOffset
      if (newDistanceFromTop < 50) {
        setScrollOnTop(true)
      } else {
        setScrollOnTop(false)
      }
      distanceFromTop = newDistanceFromTop
    }
  }

  useEffect(() => {
    setTimeout(() => setShowMenu(true), 500)
    window.addEventListener('scroll', toggleMenu())
    return window.removeEventListener('scroll', toggleMenu())
  }, [])

  useEffect(() => {
    menuOpened
      ? (document.querySelector('html').style.overflowY = 'hidden')
      : (document.querySelector('html').style.overflowY = 'visible')
  }, [menuOpened])

  const handleMenuItemClick = url => {
    setMenuOpened(false)
  }

  return (
    <Wrapper>
      <LogoHeader
        active={showMenu}
        opened={menuOpened}
        scrolled={!scrollOnTop}
        onClick={() => setMenuOpened(!menuOpened)}
      />
      <Content active={showMenu}>
        <LogoText>
          <SVG src={LogoTextSVG} />
        </LogoText>
        <nav>
          <ul>
            {navItems.map(navItem =>
              navItem.id.startsWith('DatoCmsNavBlock') ? (
                // <MenuListItem
                //   key={navItem.id}
                //   active={path === `${navItem.title}/`}
                // >
                //   {navItem.title}
                // </MenuListItem>
                ''
              ) : (
                <MenuListItem
                  key={navItem.id}
                  active={path === `${navItem.slug}/`}
                >
                  <TransitionLink
                    to={`/${navItem.slug}`}
                    title={navItem.title}
                    exit={{
                      length: 0.3,
                    }}
                    entry={{
                      delay: 0.3,
                    }}
                  >
                    {navItem.title}
                  </TransitionLink>
                </MenuListItem>
              )
            )}
          </ul>
        </nav>
        <div>
          <a href="https://app.ideolo.co/login">Log in</a>
          <SignUpButton text="Sign up" />
        </div>
      </Content>
      <FullMenu isOpened={menuOpened}>
        <NavList>
          {navItems.map(item =>
            item.id.startsWith('DatoCmsNavBlock') ? (
              ''
            ) : (
              <NavListItem
                key={item.id}
                isOpened={menuOpened}
                onClick={() => handleMenuItemClick(item.slug)}
              >
                <div>
                  <TransitionLink
                    to={`/${item.slug}`}
                    title={item.title}
                    exit={{
                      length: 0.3,
                    }}
                    entry={{
                      delay: 0.3,
                    }}
                  >
                    {item.title}
                  </TransitionLink>
                </div>
              </NavListItem>
            )
          )}
          <NavListButtons>
            <a href="https://app.ideolo.co/login">
              <Button size="L" color="white">
                Log in
              </Button>
            </a>
            <SignUpButton text="Sign up" />
          </NavListButtons>
        </NavList>
        <SectionGrayMiddle>
          <TextBox title="Blog" center>
            <h2>Want more info?</h2>
            <p>Browse our latest blog posts</p>
            <Link to="blog" title="Visit our Blog">
              <Button size="L" color="primary">
                Go to Ideolo Blog
              </Button>
            </Link>
          </TextBox>
        </SectionGrayMiddle>
      </FullMenu>
    </Wrapper>
  )
}

Header.propTypes = {
  active: PropTypes.bool,
  navItems: PropTypes.arrayOf(PropTypes.object),
}

Header.defaultProps = {
  active: false,
  navItems: [],
}

export default Header
