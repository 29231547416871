import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
  will-change: opacity;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }

  span {
    margin-right: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  svg {
    width: 20px;
    margin-right: 5px;

    path {
      fill: ${({ mode }) => (mode === 'Dark' ? '#FFFFFF' : '#000000')};
    }
  }
`

export const ToggleButton = styled.div`
  position: relative;
  height: 26px;
  cursor: pointer;

  .button-cover:before {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 12px;
    line-height: 1;
    padding: 5px;
  }

  .button-cover,
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .button {
    position: relative;
    top: 50%;
    width: 54px;
    height: 26px;
    margin: -13px auto 0 auto;
    overflow: hidden;
  }

  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }

  .button.b2 {
    border-radius: 2px;
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    width: 100%;
    background-color: var(--brand-400);
    transition: 0.3s ease all;
    opacity: 0.3;
    z-index: 1;
  }

  /* Button 3 */
  #button-3 .knobs:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 20px;
    height: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    background-color: var(--brand-500);
    border-radius: 50%;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  }

  #button-3 .checkbox:active + .knobs:before {
    width: 46px;
    border-radius: 100px;
  }

  #button-3 .checkbox:checked:active + .knobs:before {
    margin-left: -26px;
  }

  #button-3 .checkbox:checked + .knobs:before {
    content: '';
    left: 29px;
    background-color: var(--brand-500);
  }

  #button-3 .checkbox:checked ~ .layer {
    background-color: var(--brand-400);
  }
`
