import styled from 'styled-components'

export const Wrapper = styled.footer`
  z-index: 6000;
  overflow: hidden;
  max-width: 100vw;
  position: relative;
  background: var(--bg-color);
  background: linear-gradient(
    0deg,
    var(--bg-color) 0%,
    var(--bg-color) 40%,
    var(--bg-white-color) 100%
  );
`

export const Content = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 40px 0;

  a {
    font-size: 16px;
    line-height: 18px;
    opacity: 0.4;
    will-change: opacity;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }

  nav {
    ul {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        align-items: center;
        margin: 0 0 10px;

        svg {
          position: relative;
          top: -3px;
          width: auto;
          height: 30px;

          path {
            fill: var(--black-text);
          }

          + svg {
            margin-left: 5px;
            height: 17px;
          }
        }
      }
    }
  }

  ${({ theme }) => theme.media.s} {
  }

  ${({ theme }) => theme.media.l} {
    align-items: center;

    nav ul {
      flex-direction: row;

      li {
        display: flex;
        align-items: center;
        margin: 0 30px 0 0;
      }
    }
  }
`
