import styled, { css } from 'styled-components'

export const Wrapper = styled.button`
  --mask: url('data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" ><rect x="1" y="1" width="100%" height="100%" style="height:calc(100% - 2px);width:calc(100% - 2px)" rx="4" ry="4" stroke-width="2" fill="transparent" stroke="white"/></svg>');
  overflow: hidden;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.6s ease;

  &::before {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: auto;
    mask: var(--mask);
    background-image: linear-gradient(
      -45deg,
      rgba(82, 209, 57, 1) 0%,
      rgba(112, 245, 112, 1) 100%
    );
    content: '';
  }

  &::after {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      -45deg,
      rgba(112, 245, 112, 1) 0%,
      rgba(82, 209, 57, 1) 100%
    );
    transition: opacity 0.6s ease;
    will-change: opacity;
    opacity: 0;
    content: '';
  }

  p {
    z-index: 2;
    position: relative;
    font-weight: 600;
  }

  > * + * {
    margin-left: 5px;
  }

  svg path {
    transition: fill 0.3s ease;
  }

  ${({ size }) => {
    switch (size) {
      case 'S-sq':
        return css`
          padding: 10px;
        `
      case 'M-sq':
        return css`
          padding: 15px;
        `
      case 'L-sq':
        return css`
          width: 50px;
          height: 50px;
        `
      case 'S':
        return css`
          min-width: 80px;
          padding: 10px 15px;
        `
      case 'M':
        return css`
          min-width: 100px;
          height: 50px;
          padding: 0 25px;
        `
      case 'L':
        return css`
          min-width: 120px;
          height: 50px;
          padding: 0 25px;
        `
      default:
        return ''
    }
  }}
  ${({ theme, color }) => {
    switch (color) {
      case 'white':
        return css`
          &::before {
            display: none;
          }

          p {
            color: var(--white);
          }

          svg path {
            fill: var(--white);
          }
        `
      case 'light':
        return css`
          p {
            background: linear-gradient(
              -45deg,
              rgba(112, 245, 112, 1) 0%,
              rgba(82, 209, 57, 1) 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          svg path {
            fill: ${theme.colors.brand[500]};
          }
        `
      case 'primary':
        return css`
          background-image: linear-gradient(
            -45deg,
            rgba(82, 209, 57, 1) 0%,
            rgba(112, 245, 112, 1) 100%
          );
          color: var(--white);

          &::before {
            display: none;
          }

          svg path {
            fill: ${theme.colors.white};
          }
        `
      default:
        return ''
    }
  }}

  &:hover {
    &::after {
      opacity: 1;
    }

    p {
      color: var(--white);
      -webkit-background-clip: text;
      -webkit-text-fill-color: var(--white);
    }

    svg path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`
