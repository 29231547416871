import styled, { css } from 'styled-components'
import SVG from 'react-inlinesvg'

export const Wrapper = styled.button`
  z-index: ${({ theme }) => theme.zindex.logoHeader};
  position: fixed;
  top: 30px;
  left: ${({ theme }) => theme.paddings.xs};
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${({ scrolled }) => (scrolled ? '100px' : '50px')};
  height: 50px;
  border-radius: 8px;
  ${({ opened }) =>
    opened
      ? css``
      : css`
          background: linear-gradient(
            -45deg,
            rgba(112, 245, 112, 1) 0%,
            rgba(73, 198, 40, 1) 100%
          );
        `}
  will-change: width;
  opacity: ${({ active }) => (active ? '1' : '0')};
  transform: translate3d(0, ${({ active }) => (active ? '0' : '-100%')}, 0);
  transition: width 0.3s ease, opacity 0.8s ease, trasnform 0.8s ease;
  will-change: width, opacity, trasnform;

  > a {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
  }

  > a svg {
    position: relative;
    width: 35px;
    height: 35px;
  }

  svg path {
    fill: var(--white);
  }

  ${({ theme }) => theme.media.s} {
    left: ${({ theme }) => theme.paddings.s};
  }

  ${({ theme }) => theme.media.l} {
    left: ${({ theme }) => theme.paddings.l};
  }

  ${({ theme }) => theme.media.xl} {
    left: ${({ theme }) => theme.paddings.xl};
  }

  ${({ theme }) => theme.media.xxl} {
    left: ${({ theme }) => theme.paddings.xxl};
  }
`

export const Hamburger = styled.div`
  position: absolute;
  top: 0;
  left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  opacity: ${({ scrolled }) => (scrolled ? '1' : '0')};
  transition: opacity 0.3s ease;
  will-change: opacity;
  cursor: pointer;

  &::before {
    content: '';
  }

  .line {
    fill: none;
    stroke: var(--white);
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

    &1 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
    &2 {
      stroke-dasharray: 60 60;
      stroke-width: 6;
    }
    &3 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
  }

  ${({ opened }) =>
    opened &&
    css`
      .line {
        &1 {
          stroke-dasharray: 90 207;
          stroke-dashoffset: -134;
          stroke-width: 6;
        }
        &2 {
          stroke-dasharray: 1 60;
          stroke-dashoffset: -30;
          stroke-width: 6;
        }
        &3 {
          stroke-dasharray: 90 207;
          stroke-dashoffset: -134;
          stroke-width: 6;
        }
      }
    `}
`

export const MenuIcon = styled(SVG)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`
