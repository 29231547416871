import styled from 'styled-components'
import bg1 from '@images/bgs/bg1.png'

export const Wrapper = styled.section`
  z-index: 1000;
  overflow: hidden;
  position: relative;
  background: none;
`

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ hero }) => (hero ? '12rem' : '15rem')} 0;
`
