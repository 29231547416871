import React from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'

const Link = ({ to, title, children }) => {
  return (
    <TransitionLink
      to={`/${to}`}
      title={title}
      exit={{
        length: 0.3,
      }}
      entry={{
        delay: 0.3,
      }}
    >
      {children}
    </TransitionLink>
  )
}

Link.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

Link.defaultProps = {
  to: '',
  title: 'hiperlink',
  children: null,
}

export default Link
