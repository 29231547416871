import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './styles.js'

const AbsoluteSubtext = ({ subtext, children }) => {
  return (
    <Wrapper>
      {children}
      <p>{subtext}</p>
    </Wrapper>
  )
}

AbsoluteSubtext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

AbsoluteSubtext.defaultProps = {
  children: null,
}

export default AbsoluteSubtext
