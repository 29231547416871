import React from 'react'
import PropTypes from 'prop-types'

import Container from '@atoms/Container'

import { Wrapper, Content } from './styles'

const SectionGrayMiddle = ({ hero, children }) => {
  return (
    <Wrapper>
      <Container>
        <Content hero={hero}>{children}</Content>
      </Container>
    </Wrapper>
  )
}

SectionGrayMiddle.propTypes = {
  hero: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default SectionGrayMiddle
