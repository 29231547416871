import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;

  > p {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 4px 0;
    width: 100%;
    color: var(--gray);
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
  }
`
