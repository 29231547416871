import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'

import '@theme/sanitize.css'
import { mainTheme } from '@theme/theme'

import Footer from '@organisms/Footer'
import Header from '@organisms/Header'

import { GlobalStyle } from './styles.js'

const LayoutOuter = ({ path, children }) => {
  const navData = useStaticQuery(graphql`
    query NavigationQuery {
      navigationData: datoCmsNavigation {
        mainNavigation {
          ... on DatoCmsNavBlock {
            id
            title
            subpages {
              ... on DatoCmsProductPage {
                id
                slug
                title
              }
            }
          }
          ... on DatoCmsProductPage {
            id
            slug
            title
          }
          ... on DatoCmsFeaturesPage {
            id
            slug
            title
          }
          ... on DatoCmsPricingPage {
            id
            slug
            title
          }
          ... on DatoCmsContactPage {
            id
            slug
            title
          }
        }
        footerNavigation {
          ... on DatoCmsTermsPage {
            id
            slug
            title
          }
          ... on DatoCmsPrivacyPage {
            id
            slug
            title
          }
          ... on DatoCmsCookiesPage {
            id
            slug
            title
          }
          ... on DatoCmsBlogPage {
            id
            slug
            title
          }
          ... on DatoCmsContactPage {
            id
            slug
            title
          }
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      <Header
        active={true}
        path={path}
        navItems={navData.navigationData.mainNavigation}
      />
      {children}
      <Footer
        active={true}
        navItems={navData.navigationData.footerNavigation}
      />
    </ThemeProvider>
  )
}

export default LayoutOuter
