import React from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'
import SVG from 'react-inlinesvg'

import { Wrapper, Content, Bottom } from './styles.js'

import Container from '@atoms/Container'
import DarkThemeToggler from '@atoms/DarkThemeToggler'

import LogoSVG from '@images/logo.svg'
import LogoTextSVG from '@images/logo-text.svg'

const Footer = ({ active, navItems }) => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <nav>
            <ul>
              <li>
                <TransitionLink
                  to="/"
                  title="Go to home page"
                  exit={{
                    length: 0.3,
                  }}
                  entry={{
                    delay: 0.3,
                  }}
                >
                  <SVG src={LogoSVG} />
                  <SVG src={LogoTextSVG} />
                </TransitionLink>
              </li>
              {navItems.map(
                navItem =>
                  navItem && (
                    <li>
                      <TransitionLink
                        to={`/${navItem.slug}`}
                        title={navItem.title}
                        exit={{
                          length: 0.3,
                        }}
                        entry={{
                          delay: 0.3,
                        }}
                      >
                        {navItem.title}
                      </TransitionLink>
                    </li>
                  )
              )}
            </ul>
          </nav>
          <DarkThemeToggler />
        </Content>
      </Container>
    </Wrapper>
  )
}

Footer.propTypes = {
  active: PropTypes.bool,
}

Footer.defaultProps = {
  active: false,
}

export default Footer
