import { createGlobalStyle } from 'styled-components'
import bg1 from '@images/features/feature-preview-roadmap.png'

export const GlobalStyle = createGlobalStyle`
  @import url("https://use.typekit.net/moc3agp.css");

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    --brand-500: #53d23d;
    --bg-white-color: #222222;
    overflow-x: hidden;
    width: 100vw;
    font-family:  dicsans, sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  body {
    --black: #000000;
    --white: #ffffff;
    --gray: #afb8c1;
    --bg-color: #f2f2f2;
    --bg-white-color: #ffffff;
    --black-text: #1d1d1d;
    --brand-400: #21ec80;
    --brand-500: #53d23d;
    --brand-600: #1dab5f;
    position: relative;
    width: 100vw;
    margin: 0;
    padding: 0;
    font-family: dicsans, sans-serif;
    font-weight: 400;
    background-color: var(--bg-color);
    color: var(--black-text);

    &:before {
      position: fixed;
      top: -100vh;
      left: 0;
      width: 300vw;
      height: 300vh;
      background: 350px 150px url(${bg1}) no-repeat;
      background-size: 40%;
      transform: rotate(20deg);
      transform-origin: top left;
      filter: grayscale(1);
      content: '';
      opacity: 0.095;
    }
  }

  body.dark-theme {
    --white: #222222;
    --bg-color: #1D1D1D;
    --bg-white-color: #222222;
    --black-text: #ffffff;
    --brand-400: #1dab5f;
    --brand-600: #21ec80;
  }

  body::-webkit-scrollbar {
    width: 8px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--brand-500) var(--bg-white-color);
  }
  body::-webkit-scrollbar-track {
    background: var(--bg-white-color);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--brand-500) ;
    border-radius: 0;
    border: 0 solid var(--bg-white-color);
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.25;
    letter-spacing: -0.5px;
  }

  .tl-edges {
    overflow: visible;
  }
  .tl-wrapper {
    float: none;
  }
`
