import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './styles'

const TextBox = ({ title, center, middle, children }) => {
  return (
    <Wrapper center={center} middle={middle}>
      {title && <strong>{title}</strong>}
      {children}
    </Wrapper>
  )
}

TextBox.propTypes = {
  title: PropTypes.string,
  center: PropTypes.bool,
  middle: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

TextBox.defaultProps = {
  title: null,
  center: false,
  middle: true,
  children: null,
}

export default TextBox
