import styled, { css } from 'styled-components'

export const Wrapper = styled.header`
  z-index: ${({ theme }) => theme.zindex.header};
  position: relative;
  width: 100vw;
  padding: 30px 0;
  background: var(--bg-white-color);
`

export const LogoText = styled.div`
  margin-left: 65px;
  width: auto;

  svg {
    width: auto;
    height: 25px;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${({ theme }) => theme.paddings.xs};
  height: 50px;
  opacity: ${({ active }) => (active ? '1' : '0')};
  transform: translate3d(0, ${({ active }) => (active ? '0' : '-20%')}, 0);
  transition: all 0.8s ease;
  will-change: opacity, trasnform;

  > h1 {
    margin-left: 70px;
    font-size: 36px;
    line-height: 60px;
    font-weight: 600;
  }

  > div > a {
    display: none;

    &:nth-child(1) {
      margin-right: 30px;
    }
  }

  a {
    font-weight: 600;
    transition: color 0.3s ease;
    will-change: color;

    &:hover {
      color: ${({ theme }) => theme.colors.brand[500]};
    }
  }

  nav {
    position: absolute;
    left: 50%;
    display: none;
    transform: translate3d(-50%, 0, 0);

    ul {
      display: flex;

      li {
        position: relative;
        margin-right: 35px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  ${({ theme }) => theme.media.s} {
    margin: 0 ${({ theme }) => theme.paddings.s};

    nav {
      display: block;
    }

    > div > a {
      display: inline-block;
    }
  }

  ${({ theme }) => theme.media.l} {
    margin: 0 ${({ theme }) => theme.paddings.l};
  }

  ${({ theme }) => theme.media.xl} {
    margin: 0 ${({ theme }) => theme.paddings.xl};
  }

  ${({ theme }) => theme.media.xxl} {
    margin: 0 ${({ theme }) => theme.paddings.xxl};
  }
`

export const MenuListItem = styled.li`
  &::after {
    position: absolute;
    bottom: -7px;
    left: 0;
    width: ${({ active }) => (active ? '100%' : '0')};
    height: 3px;
    background: linear-gradient(
      -45deg,
      rgba(112, 245, 112, 1) 0%,
      rgba(82, 209, 57, 1) 100%
    );
    content: '';
    transition: width 0.3s ease;
    will-change: width;
  }

  &::hover {
    background: linear-gradient(
      -45deg,
      rgba(112, 245, 112, 1) 0%,
      rgba(82, 209, 57, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const FullMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  width: 100vw;
  height: ${({ isOpened }) => (isOpened ? '100vh' : '0')};
  background: var(--bg-color);
  transition: height 0.4s ease;
  will-change: height;
  z-index: 5000;

  > section {
    display: none;
  }

  ${({ theme }) => theme.media.l} {
    grid-template-columns: 1fr 1fr;

    > section {
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        transition: 0.3s 0.5s ease;
        ${({ isOpened }) =>
          isOpened
            ? css`
                opacity: 1;
              `
            : css`
                opacity: 0;
              `}
      }
    }
  }
`

export const NavList = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: linear-gradient(
    -45deg,
    rgba(112, 245, 112, 1) 0%,
    rgba(73, 198, 40, 1) 100%
  );
  color: var(--white);
  transition: height 0.4s ease;
  will-change: height;
  z-index: 5000;
`

export const NavListItem = styled.div`
  transition: 0.3s ease;
  ${({ isOpened }) =>
    isOpened
      ? css`
          opacity: 1;
          transform: translate3d(0, 0, 0);
        `
      : css`
          opacity: 0;
          transform: translate3d(0, 20px, 0);
        `}

  &:nth-child(1) {
    transition-delay: 0.5s;
  }

  &:nth-child(2) {
    transition-delay: 0.6s;
  }

  &:nth-child(3) {
    transition-delay: 0.7s;
  }

  &:nth-child(4) {
    transition-delay: 0.8s;
  }

  &:nth-child(5) {
    transition-delay: 0.9s;
  }

  > div {
    position: relative;
    display: inline-block;
    padding: 20px 0;
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    transition: 0.3s ease;
    ${({ isOpened }) =>
      isOpened
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 20px, 0);
          `}

    &::before {
      content: '';
      position: absolute;
      bottom: 10px;
      width: 0;
      height: 3px;
      background: var(--white);
      transition: width 0.4s ease;
      will-change: width;
    }

    &:hover:before,
    &:hover:after {
      width: 100%;
    }
  }
`

export const NavListButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0 0 0;

  > a + a {
    margin: 20px 0 0 0;
  }

  ${({ theme }) => theme.media.l} {
    flex-direction: row;

    > a + a {
      margin: 0 0 0 20px;
    }
  }
`
