import React, { useState, useEffect } from 'react'
import SVG from 'react-inlinesvg'

import { Wrapper, ToggleButton } from './styles'

import IconSun from '@images/sun.svg'
import IconMoon from '@images/moon.svg'

const DarkThemeToggler = () => {
  const [currentTheme, setTheme] = useState('Light')

  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      setTheme('Dark')
      document.body.classList.toggle('dark-theme')
    }
  }, [])

  const handkleClick = () => {
    currentTheme === 'Light' ? setTheme('Dark') : setTheme('Light')
    document.body.classList.toggle('dark-theme')
  }

  return (
    <Wrapper mode={currentTheme}>
      <SVG src={IconMoon} />
      <span>Night mode</span>
      <ToggleButton onClick={handkleClick}>
        <div class="button r" id="button-3">
          <input
            type="checkbox"
            class="checkbox"
            checked={currentTheme === 'Dark'}
          />
          <div class="knobs"></div>
          <div class="layer"></div>
        </div>
      </ToggleButton>
    </Wrapper>
  )
}

export default DarkThemeToggler
