import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
  justify-content: ${({ middle }) => (middle ? 'center' : 'flex-start')};
  margin: ${({ center }) => (center ? '0 auto' : '0')};
  max-width: 650px;

  > strong {
    font-size: 10px;
    font-weight: 600;
    line-height: 2;
    letter-spacing: 2px;
    text-transform: uppercase;
    ${({ center }) => center && 'text-align: center;'}
    color: ${({ theme }) => theme.colors.brand[500]};
    background: linear-gradient(
      -45deg,
      rgba(112, 245, 112, 1) 0%,
      rgba(82, 209, 57, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  > h2 {
    margin: 0 0 12px;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
    ${({ center }) => center && 'text-align: center;'}
  }

  > h3 {
    margin: 0 0 12px;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.2;
    ${({ center }) => center && 'text-align: center;'}
  }

  > p {
    margin: 0 0 20px;
    max-width: 600px;
    font-size: 18px;
    line-height: 1.5;
    ${({ center }) => center && 'text-align: center;'}

    a {
      color: var(--brand-500);

      svg {
        position: relative;
        top: -2px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;

        path {
          fill: var(--brand-500);
        }
      }
    }
  }

  ${({ theme }) => theme.media.l} {
    > h2 {
      margin: 0 0 15px;
      font-size: 32px;
    }

    > h3 {
      margin: 0 0 9px;
      font-size: 28px;
    }

    > p {
      font-size: 20px;
    }
  }
`
