import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import AbsoluteSubtext from '@atoms/AbsoluteSubtext'
import Button from '@atoms/Button'

const SignUpButton = ({ text, buttonColor, noCreditCard }) => {
  const [refCode, setRefCode] = useState('')

  useEffect(() => {
    localStorage.getItem('ref') !== 'null' &&
      setRefCode(localStorage.getItem('ref'))
  }, [])

  return noCreditCard ? (
    <AbsoluteSubtext subtext="No credit card needed">
      <a href={`https://app.ideolo.co/signup/${refCode}`}>
        <Button size="L" color={buttonColor}>
          {text}
        </Button>
      </a>
    </AbsoluteSubtext>
  ) : (
    <a href={`https://app.ideolo.co/signup/${refCode}`}>
      <Button size="L" color="primary">
        {text}
      </Button>
    </a>
  )
}

SignUpButton.propTypes = {
  text: PropTypes.string,
  buttonColor: PropTypes.string,
  noCreditCard: PropTypes.bool,
}

SignUpButton.defaultProps = {
  text: 'Sign up',
  buttonColor: 'primary',
  noCreditCard: false,
}

export default SignUpButton
