import React from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'
import SVG from 'react-inlinesvg'

import { Wrapper, Hamburger, MenuIcon } from './styles.js'

import LogoSVG from '@images/logo.svg'
import IconMenuSVG from '@images/menu.svg'

const LogoHeader = ({ active, scrolled, opened, onClick }) => {
  const handleClick = () => {
    onClick && onClick()
  }
  return (
    <Wrapper scrolled={scrolled} active={active} opened={opened}>
      <TransitionLink
        to="/"
        title="Go to index"
        exit={{
          length: 0.3,
        }}
        entry={{
          delay: 0.3,
        }}
      >
        <SVG src={LogoSVG} />
      </TransitionLink>
      <Hamburger
        opened={opened}
        scrolled={scrolled}
        onClick={() => handleClick()}
      >
        <MenuIcon src={IconMenuSVG} />
      </Hamburger>
    </Wrapper>
  )
}

LogoHeader.propTypes = {
  scrolled: PropTypes.bool,
  opened: PropTypes.bool,
  onClick: PropTypes.func,
}

LogoHeader.defaultProps = {
  scrolled: false,
  opened: false,
  onClick: null,
}

export default LogoHeader
