import React from 'react'
import PropTypes from 'prop-types'
// import history from "../../history/History";

import { Wrapper } from './styles'

const Button = ({ size, color, disabled, to, onClick, children }) => {
  const handleOnClick = e => {
    onClick && onClick(e)
    // to && history.push(to);
  }

  return (
    <Wrapper
      as={to ? 'a' : 'button'}
      size={size}
      color={color}
      disabled={disabled}
      onClick={handleOnClick}
      props
    >
      <p>{children}</p>
    </Wrapper>
  )
}

Button.propTypes = {
  size: PropTypes.oneOf(['S-sq', 'M-sq', 'L-sq', 'S', 'M', 'L']),
  color: PropTypes.oneOf(['white', 'light', 'primary']),
  disabled: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Button
