import { colors } from './colors'

export const mainTheme = {
  colors,
  zindex: {
    logoHeader: '8500',
    fixedHeader: '8250',
    header: '8000',
    section: '1000',
  },
  media: {
    tablet: '@media (min-width: 760px)',
    desktop: '@media (min-width: 1280px)',
    bigDesktop: '@media (min-width: 1680px)',
    tv: '@media (min-width: 2000px)',
    s: '@media (min-width: 768px)',
    m: '@media (min-width: 1024px)',
    l: '@media (min-width: 1280px)',
    xl: '@media (min-width: 1520px)',
    xxl: '@media (min-width: 1920px)',
  },
  paddings: {
    mobile: '30px',
    tablet: '40px',
    desktop: '50px',
    bigDesktop: '80px',
    tv: '110px',
    xs: '30px',
    s: '40px',
    m: '45px',
    l: '50px',
    xl: '80px',
    xxl: '110px',
  },
  margins: {
    maxWidth: '1700px',
    phonePadding: `25px`,
    tabletPadding: `30px`,
    desktopPadding: `80px`,
    bigDesktopPadding: `120px`,
    tvPadding: `170px`,
  },
}
