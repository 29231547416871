export const colors = {
  ghost: 'transparent',
  white: '#ffffff',
  white2: '#fafafa',
  black: '#000000',
  black2: '#1a1a1a',
  blue: '#0031ff',
  red: '#e6001c',
  green: '#35be25',
  brand: {
    600: '#1dab5f',
    500: '#53d23d',
    400: '#21ec80',
  },
}
